<template>
    <div>
        <b-upload v-model="files" class="file-label" multiple drag-drop expanded v-on:input="addFileInfo">
            <section class="section">
                <div class="content has-text-centered">
                <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                </p>
                <p>Solte arquivos ou clique para selecionar</p>
                </div>
            </section>
        </b-upload>
        <div class="tags m-4">
            <span class="tag is-primary" v-for="(file, index) in files" :key="index">
                {{ file.name  }}
                <button class="delete is-small" type="button" @click="deleteDropFile(index)" />
            </span>
        </div>
    </div>
</template>
<script>
    export default {
        name: "DragAndDrop",
        props: {
            files_info: {
                type: Array,
                default: () => [],
                required: true
            },
        },
        data() {
            return {
                files: []
            }
        },
        methods: {
            deleteDropFile(index) {
                this.files.splice(index, 1)
                this.files_info.splice(index, 1)
            },
            addFileInfo(files) {
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = (e) => {
                        if (this.files_info.find(f => f.name === file.name)) return
                        this.files_info.push({
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            base64:reader.result.substr(reader.result.indexOf(',') + 1)
                        })
                    };
                });
            }
        },
    }
</script>