<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Mensagens </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-widget class="tile is-child" icon="message" :number="getMessagesDashboard.total" label="Total" />
        <card-widget class="tile is-child" type="is-warning" icon="message" :number="getMessagesDashboard.queued"
          label="Na fila" />
        <card-widget class="tile is-child" type="is-info" icon="message" :number="getMessagesDashboard.procecced"
          label="Processados" />
      </tiles>
      <tiles>
        <card-widget class="tile is-child" type="is-success" icon="message" :number="getMessagesDashboard.sent"
          label="Enviados" />
        <card-widget class="tile is-child" type="is-success" icon="message" :number="getMessagesDashboard.received"
          label="Recebidos/lidos" />
        <card-widget class="tile is-child" type="is-danger" icon="message" :number="getMessagesDashboard.error"
          label="Erro ao enviar" />
      </tiles>
      <div class="row columns is-multiline" v-if="progress > 0 && progress < 100">
        <div class="column is-full has-background-primary">
          <label class="has-text-white">Salvando mensagens...</label>
          <b-progress type="is-white is-large" :value="progress" show-value>
            <span><strong>{{ progress }}%</strong></span>
          </b-progress>
        </div>
      </div>
      <div class="row columns is-multiline">
        <div class="column is-half">
          <b-field label="Contatos" label-position="on-border">
            <multiselect v-model="contactsFilter" :multiple="true" :options="getContacts" label="name" track-by="id"
              select-label="" deselect-label="">
              <template slot="placeholder">Contatos</template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field type="is-warning" label="Telefone do cliente" label-position="on-border">
            <b-input expanded type="search" v-model="phoneFilter" placeholder="Telefone do cliente"
              @keydown.native.enter="applyMessagesFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Data de" label-position="on-border">
            <b-input expanded type="date" v-model="dateFromFilter" placeholder="Nome ou telefone do cliente"
              @keydown.native.enter="applyMessagesFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <b-field label="Data até" label-position="on-border">
            <b-input expanded type="date" v-model="dateToFilter" placeholder="Nome ou telefone do cliente"
              @keydown.native.enter="applyMessagesFilter()">
            </b-input>
          </b-field>
        </div>
        <div class="column is-one-third">
          <p class="control">
            <button class="button is-primary is-fullwidth" @click="applyMessagesFilter()">
              Filtrar
            </button>
          </p>
        </div>
        <hr>
        <div class="column is-one-third">
          <p class="control">
            <b-button icon-right="download" class="button is-info is-fullwidth" @click="exportData()"
              :loading="isExporting">
              Exportar
            </b-button>
          </p>
        </div>
        <div class="column is-one-third">
          <p class="control">
            <button class="button is-info is-fullwidth" @click="confirmAllMessages()">
              Confirmar mens. pendentes
            </button>
          </p>
        </div>
        <div class="column is-one-third">
          <p class="control">
            <button class="button is-danger is-fullwidth" @click="confirmCancelAllPendingMessages()">
              Cancelar mens. pendentes
            </button>
          </p>
        </div>
      </div>
      <MessagesList :messages="allMessages" v-on:confirmMessage="confirmMessage" v-on:cancelMessage="cancelMessage"
        v-on:downloadFile="downloadFile" />
      <ModalInsertMessage v-if="isComponentModalActive" v-on:addMessage="handleAddMessage" v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive" :contacts="allContacts" :contactsGroups="allContactsGroups"
        :businessHours="allEnabledBusinessHours" :whatsappSettings="allSettingsEnabled" :tags="allEnabledTags"
        :departments="getDepartments" :allAttendants="allAttendantsEnabled" :templates="allTemplates"
        style="overflow-y: auto;" />
      <b-loading v-if="fetchingMessages" :is-full-page="true" v-model="fetchingMessages" :can-cancel="false"></b-loading>
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Nova mensagem" />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import MessagesList from '@/components/messages/MessagesList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertMessage from '@/components/modals/ModalInsertMessage'
import { io } from 'socket.io-client'
import multiselect from 'vue-multiselect'
import { addDays } from 'date-fns'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import { getUrl } from '../utils/api'
import exportExcel from '../exportExcel'

export default {
  name: 'Messages',
  data() {
    return {
      isComponentModalActive: false,
      socket: null,
      dateFromFilter: '',
      dateToFilter: '',
      contactsFilter: [],
      phoneFilter: '',
      progress: 0,
      isExporting: false
    }
  },
  components: {
    MessagesList,
    FabButton,
    ModalInsertMessage,
    TitleBar,
    CardWidget,
    Tiles,
    HeroBar,
    multiselect
  },
  computed: {
    ...mapGetters([
      'allMessages',
      'fetchingMessages',
      'allContacts',
      'fetchingContacts',
      'getToken',
      'allContactsGroups',
      'allSettingsEnabled',
      'allEnabledTags',
      'allEnabledBusinessHours',
      'allTemplates',
      'allAttendantsEnabled',
      'allDepartments'
    ]),
    titleStack() {
      return ['Cadastros', 'Mensagens']
    },
    getMessagesDashboard() {
      return this.allMessages.reduce((acc, message) => {
        acc.total++
        switch (message.status) {
          case 'CONFIRMED':
          case 'WAITING':
          case 'UPLOADING':
          case 'WAITING_TICKET':
            acc.queued++
            break
          case 'SEEN':
          case 'DISTRIBUTED':
            acc.sent++
            acc.received++
            acc.procecced++
            break
          case 'SUCCESS':
          case 'SAVED':
            acc.procecced++
            acc.sent++
            break
          default:
            acc.error++
            acc.procecced++
            break
        }
        return acc
      }, { total: 0, queued: 0, received: 0, sent: 0, error: 0, procecced: 0 })
    },
    getContacts() {
      return this.allContacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name}`
        return c
      })
    },
    getDepartments() {
      return this.allDepartments.filter(d => d.enabled)
    },
    getTemplates () {
      return this.allTemplates.filter(t => t.status === 'APPROVED')
    }
  },
  methods: {
    ...mapActions([
      'fetchMessages',
      'sendMessage',
      'fetchContacts',
      'updateMessageLocal',
      'confirmMessageToSend',
      'cancelMessageFromSend',
      'confirmAllMessagesToSend',
      'fetchContactsGroups',
      'fetchSettings',
      'fetchEnabledTags',
      'fetchBusinessHours',
      'cancelAllPendingMessages',
      'fetchTemplates',
      'fetchAttendants',
      'fetchDepartments'
    ]),
    applyMessagesFilter() {
      this.fetchMessages({
        client_phone: this.phoneFilter,
        contacts_filter: this.contactsFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter
      })
    },
    confirmMessage(message) {
      this.confirmMessageToSend(message)
    },
    cancelMessage(message) {
      this.cancelMessageFromSend(message)
    },
    confirmCancelAllPendingMessages(businessHours) {
      this.$buefy.dialog.confirm({
        title: 'Cancelar envio de mensagens',
        message: 'Deseja realmente cancelar o envio de todas as mensagens pendentes?',
        confirmText: 'Cancelar envio',
        type: 'is-danger',
        size: 'is-big',
        hasIcon: true,
        cancelText: 'Continuar enviando',
        onConfirm: () => this.cancelAllMessages()
      })
    },
    async cancelAllMessages() {
      try {
        await this.cancelAllPendingMessages();
      } finally {
        await this.fetchMessages({
          client_phone: this.phoneFilter,
          contacts_filter: this.contactsFilter,
          date_from_filter: this.dateFromFilter,
          date_to_filter: this.dateToFilter
        })
      }
    },
    confirmAllMessages() {
      this.confirmAllMessagesToSend()
    },
    async handleAddMessage(message) {
      this.closeModal()
      
      await this.sendMessage({
        message,
        progressEvent: (progressEvent) => {
          console.log('progressEvent', progressEvent)
          const { loaded, total } = progressEvent
          const percent = Math.ceil(((loaded * 100) / total) / 2)
          this.progress = percent
        }
      }).catch((error) => {
        this.progress = 0

        if (error.response.status === 422) return;

        this.$buefy.dialog.alert({
          title: 'Erro ao enviar mensagem',
          message: error.response ? error.response.data.message : 'Erro ao enviar mensagem',
          confirmText: 'Ok',
          type: 'is-danger',
          size: 'is-big',
          hasIcon: true
        })
      })
      
      this.applyMessagesFilter()
    },
    messageProgressChanged({ progress }) {
      this.progress = Math.floor(50 + (progress / 2))
    },
    handleAddClick() {
      this.fetchContactsGroups()
      this.fetchContacts()
      this.fetchEnabledTags()
      this.fetchAttendants()
      this.fetchDepartments()
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal() {
      this.isComponentModalActive = false
    },
    downloadFile(messageId) {
      try {
        const a = document.createElement('a')
        a.href = getUrl(`messages/download/${messageId}`)
        a.setAttribute('target', '_blank')
        a.click()
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Erro ao baixar arquivo',
          type: 'is-danger'
        })
        console.log(error)
      }
    },
    async exportData() {
      this.isExporting = true
      await this.fetchMessages({
        client_phone: this.phoneFilter,
        contacts_filter: this.contactsFilter,
        date_from_filter: this.dateFromFilter,
        date_to_filter: this.dateToFilter
      })
      try {
        const messages = this.allMessages.map(message => {
          return {
            id: message.id,
            from: message.from,
            to: message.contactTo || message.to,
            message: message.message,
            status: message.statusMessage || message.status,
            created_at: new Date(message.created_at),
            schedule_date: message.schedule_date ? new Date(message.schedule_date) : '',
            confirmation_date: new Date(message.confirmation_date),
            send_date: new Date(message.send_date)
          }
        })

        const sheets = [
          {
            name: 'Tickets',
            headers: [
              { header: 'ID', key: 'id', width: 10 },
              { header: 'De', key: 'from', width: 20 },
              { header: 'Para', key: 'to', width: 20 },
              { header: 'Mensagem', key: 'message', width: 20 },
              { header: 'Status', key: 'status', width: 20 },
              { header: 'Data de criação ', key: 'created_at', width: 20 }, // tratar para não exibir caso seja um grupo
              { header: 'Data de agendamento', key: 'schedule_date', width: 20 },
              { header: 'Data de confirmação', key: 'confirmation_date', width: 20 },
              { header: 'Data de envio', key: 'send_date', width: 20 }
            ],
            data: messages
          }
        ]

        await exportExcel('Mensagens Agendadas', sheets)

        this.isExporting = false
      } catch (error) {
        console.log(error)
        this.isExporting = false
        this.$buefy.toast.open('Não foi possível exportar seu relatório de tickets')
      }
    }
  },
  beforeDestroy() {
    if (this.socket) {
      console.warn('disconnecting')
      this.socket.disconnect()
    }
  },
  created() {
    this.dateFromFilter = addDays(new Date(), -5).toISOString().slice(0, 10)
    this.dateToFilter = new Date().toISOString().slice(0, 10)

    this.fetchSettings()
    this.fetchContacts()
    this.fetchBusinessHours()
    this.fetchTemplates()
    this.applyMessagesFilter()
    this.socket = io(process.env.VUE_APP_SOCKETIO_URL ?? process.env.VUE_APP_GTCHAT_URL, {
      reconnectionDelayMax: 10000,
      query: {
        token: this.getToken
      },
      path: `${process.env.VUE_APP_SOCKETIO_PATH ?? '/socket.io'}`
    })
    this.socket.on('message', (message) => {
      this.updateMessageLocal(message)
    })
    this.socket.on('scheduled_message_progress_changed', data => {
      this.messageProgressChanged(data)
    })
  }
}
</script>

<style>
.multiselect--active {
  z-index: 9999;
}

.multiselect__content-wrapper {
  z-index: 9999 !important;
}
</style>
