<template>
  <div>
    <div>
      <b-table
        :data="allMessages"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :paginated="true"
        :mobile-cards="true"
        scrollable
        detailed
        detail-key="id"
        :detail-transition="transitionName"
      >
        <b-table-column
          field="ID"
          width="200"
          label="ID"
          v-slot="props"
          searchable
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column
          field="from"
          label="De"
          v-slot="props"
          searchable
        >
          {{ props.row.from }}
        </b-table-column>

        <b-table-column
          field="contactTo"
          label="Para"
          v-slot="props"
          searchable
        >
          {{ props.row.contactTo }}
        </b-table-column>

        <b-table-column
          field="message"
          width="200"
          label="Mensagem"
          v-slot="props"
          searchable
        >
          <a class="message-link" @click="props.toggleDetails(props.row)">
            {{ props.row.message | truncate(80) }}
          </a>
        </b-table-column>

        <b-table-column
          field="statusMessage"
          label="Status"
          v-slot="props"
          searchable
        >
          <span class="tag is-success" v-if="props.row.status == 'SUCCESS'">
            {{ props.row.statusMessage }}
          </span>
          <span
            class="tag is-warning"
            v-else-if="props.row.status == 'WAITING'"
          >
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-info" v-else-if="props.row.status == 'CONFIRMED'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-success" v-else-if="props.row.status == 'SEEN'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-success" v-else-if="props.row.status == 'DISTRIBUTED'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-warning" v-else-if="props.row.status == 'SAVED'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-warning" v-else-if="props.row.status == 'UPLOADING'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-warning" v-else-if="props.row.status == 'WAITING_TICKET'">
            {{ props.row.statusMessage }}
          </span>
          <span class="tag is-danger" v-else>
            {{ props.row.statusMessage }}
          </span>
        </b-table-column>

        <b-table-column field="date" label="Data de cadastro" centered v-slot="props">
          {{
            props.row.created_at
              ? formatDateTime(props.row.created_at)
              : ""
          }}
        </b-table-column>

        <b-table-column field="date" label="Agendado para" centered v-slot="props">
          {{
            props.row.schedule_date
              ? formatDateTime(props.row.schedule_date)
              : ""
          }}
        </b-table-column>

        <b-table-column field="date" label="Confirmado em" centered v-slot="props">
          {{
            props.row.confirmation_date
              ? formatDateTime(props.row.confirmation_date)
              : ""
          }}
        </b-table-column>

        <b-table-column
          field="send_date"
          label="Data/hora de envio"
          centered
          v-slot="props"
        >
          {{ props.row.send_date ? formatDateTime(props.row.send_date) : "" }}
        </b-table-column>

        <b-table-column field="confirm" label="Confirmar envio" v-slot="props">
          <button
            v-if="props.row.status === 'WAITING'"
            class="button is-info"
            expanded
            type="button"
            @click="info(props.row, 'CONFIRM')"
          >
            Confirmar envio
          </button>
        </b-table-column>

        <b-table-column field="cancel" label="Cancelar envio" v-slot="props">
          <button
            v-if="props.row.status === 'WAITING' || props.row.status === 'CONFIRMED'"
            class="button is-danger"
            expanded
            type="button"
            @click="info(props.row, 'CANCEL')"
          >
            Cancelar envio
          </button>
        </b-table-column>

        <template #detail="props">
          <div class="columns">
            <div class="column is-narrow" v-if="props.row.file_id || props.row.business_hours || (props.row.messages_files && props.row.messages_files.length > 0)">
              <div class="box" style="width: 205px;">
                  <b-field v-if="props.row.file_id || (props.row.messages_files && props.row.messages_files.length > 0)" >
                    <b-button type="is-info" icon-left="download" @click="downloadFile(props.row.id)">Baixar Arquivo</b-button>
                  </b-field>
                  <b-field v-if="props.row.business_hours" label="Horário de funcionamento" label-position="inside">
                    <b-input :value="props.row.business_hours.id + ' - ' + props.row.business_hours.description"/>
                  </b-field>
              </div>
            </div>
            <div class="column" v-if="props.row.message">
              <div class="box">
                  <b-field label="Mensagem" label-position="on-border" expanded>
                    <b-input
                      type="textarea"
                      :value="props.row.message"
                      :readonly="true"
                    />
                  </b-field>
              </div>
            </div>
            <div class="column" v-if="props.row.sending_error">
              <div class="box">
                <b-field label="Erro de envio" label-position="on-border" expanded>
                  <b-input
                    type="textarea"
                    :value="props.row.sending_error"
                    :readonly="true"
                  />
                </b-field>
              </div>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="has-text-left">
            Quantidade de registros {{ messages.length }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'

export default {
  name: 'MessagesList',
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  mixins: [mixin],
  computed: {
    allMessages: {
      get () {
        return this.messages
      },
      set () {
        // do nothing
      }
    },
    transitionName () {
      if (this.useTransition) {
        return 'fade'
      } else {
        return null
      }
    }
  },
  methods: {
    info (message, type) {
      switch (type) {
        case 'CONFIRM':
          this.$emit('confirmMessage', { message_id: message.id })
          break
        case 'CANCEL':
          this.$emit('cancelMessage', { message_id: message.id })
          break
      }
    },
    downloadFile (messageId) {
      this.$emit('downloadFile', messageId)
    }
  },
  data () {
    return {
      showDetailIcon: false,
      useTransition: true
    }
  },
  filters: {
    truncate (value, length) {
      return value.length > length
        ? value.substr(0, length) + '...'
        : value
    }
  }
}
</script>

<style>
.message-link {
  color:#000 !important;
}
</style>
